const preview = Number(process.env.CONTENTFUL_PREVIEW) === 1

module.exports = {
  space: process.env.CONTENTFUL_SPACE_ID,
  environment: process.env.CONTENTFUL_ENVIRONMENT,
  hostRest:
      Number(process.env.CONTENTFUL_PREVIEW) === 1 ? 'preview.contentful.com' : 'cdn.contentful.com',
  host: `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE_ID}/environments/${process.env.CONTENTFUL_ENVIRONMENT}`,
  accessToken:
      Number(process.env.CONTENTFUL_PREVIEW) === 1
        ? process.env.CONTENTFUL_ACCESS_TOKEN_PREVIEW
        : process.env.CONTENTFUL_ACCESS_TOKEN,
}
