import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import Immutable from 'seamless-immutable'
import { loadingBarReducer } from 'react-redux-loading-bar'

import * as reducers from './reducers'
import rootSaga from './sagas'


export default (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const enhancer = composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  )
  const combinedReducers = combineReducers({ ...reducers, loadingBar: loadingBarReducer })
  const store = createStore(combinedReducers, Immutable.static.from(initialState), enhancer)

  store.saga = sagaMiddleware
  store.sagaTask = sagaMiddleware.run(rootSaga)
  return store
}
