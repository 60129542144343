import { all, fork } from 'redux-saga/effects'

// App
import AppSagas from './app/sagas'
import NewsletterSagas from './newsletter/sagas'


function* loop() {
  yield all([AppSagas.loop(), NewsletterSagas.loop()])
}

export default function* rootSaga() {
  yield fork(loop)
}
