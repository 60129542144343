import { all, put, takeLatest, call } from 'redux-saga/effects'
import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import * as appConfiguration from '../../configuration'

import { actions as NewsletterActions, selectors as NewsletterSelectors } from './redux'


export default class NewsletterSagas {

  static* initConnected() {
    if (localStorage.key('connected')) {
      const connected = localStorage.getItem('connected')

      if (connected === 'connected') {
        yield all([
          put(NewsletterActions.setConnected(true)),
          put(NewsletterActions.setComplete(false)),
        ])
      } else {
        yield all([
          put(NewsletterActions.setConnected(false)),
          put(NewsletterActions.setComplete(false)),
        ])
      }
    }
  }
  static* canAccess({ payload }) {
    yield put(showLoading())
    try {
      const result = yield call(NewsletterSagas.callApiAccess, payload)

      if (result?.data?.access) {
        yield all([
          put(NewsletterActions.setConnected(true)),
          put(NewsletterActions.setComplete(false)),
        ])

        localStorage.setItem('connected', 'connected')
      } else {
        yield all([
          put(NewsletterActions.setConnected(false)),
          put(NewsletterActions.setComplete(true)),
        ])
      }
    } catch (e) {
      yield all([
        put(NewsletterActions.setConnected(false)),
        put(NewsletterActions.setComplete(false)),
      ])
    }

    yield put(hideLoading())
  }

  static* subscribeNewsletter({ payload }) {
    yield put(showLoading())

    console.log('NewsletterSagas.callApi')

    try {
      const result = yield call(NewsletterSagas.callApi, payload)

      console.log(result)
      console.log(result)
      if (result?.data?.id) {
        localStorage.setItem('connected', 'connected')

        yield all([
          put(NewsletterActions.setConnected(true)),
          put(NewsletterActions.setComplete(false)),
          put(NewsletterActions.setSuccess(true)),
          put(NewsletterActions.setError(false)),
        ])
      } else {
        localStorage.removeItem('connected')

        yield all([
          put(NewsletterActions.setConnected(false)),
          put(NewsletterActions.setComplete(true)),
          put(NewsletterActions.setSuccess(false)),
          put(NewsletterActions.setError(true)),
        ])
      }
    } catch (e) {
      localStorage.removeItem('connected')

      yield all([
        put(NewsletterActions.setConnected(false)),
        put(NewsletterActions.setComplete(true)),
        put(NewsletterActions.setSuccess(false)),
        put(NewsletterActions.setError(true)),
      ])
    }

    yield put(hideLoading())
  }

  static* callApiAccess(params) {
    return yield axios({
      method: 'POST',
      url: '/access',
      baseURL: appConfiguration.app.api,
      ...appConfiguration.app.basicAuthUser ? {
        auth: {
          username: appConfiguration.app.basicAuthUser,
          password: appConfiguration.app.basicAuthPassword,
        },
      } : [],
      params,
    })
  }

  static* callApi(params) {
    return yield axios({
      method: 'POST',
      url: '/newsletter_fall22',
      baseURL: appConfiguration.app.api,
      ...appConfiguration.app.basicAuthUser ? {
        auth: {
          username: appConfiguration.app.basicAuthUser,
          password: appConfiguration.app.basicAuthPassword,
        },
      } : [],
      params,
    })
  }

  static* loop() {
    yield all([
      takeLatest(NewsletterActions.subscribeNewsletter.getType(), this.subscribeNewsletter),
      takeLatest(NewsletterActions.canAccess.getType(), this.canAccess),
      takeLatest(NewsletterActions.initConnected.getType(), this.initConnected),
    ])
  }

}
