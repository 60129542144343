import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

//
// Initial state
//
const initialState = {
  error: false,
  success: false,
  connected: false,
  complete: false,
}

//
// Actions
//
export const actions = {
  setConnected: createAction('Nesletter: setConnected', (connected) => connected),
  setComplete: createAction('Nesletter: setComplete', (complete) => complete),
  setError: createAction('Nesletter: setError', (error) => error),
  setSuccess: createAction('Nesletter: setSuccess', (success) => success),
  initConnected: createAction('Nesletter: initConnected'),
  canAccess: createAction('Nesletter: canAccess'),
  subscribeNewsletter: createAction('Nesletter: subscribeNewsletter'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setConnected]: (state, connected) => Immutable.merge(state, { connected }),
    [actions.setComplete]: (state, complete) => Immutable.merge(state, { complete }),
    [actions.setError]: (state, error) => Immutable.merge(state, { error }),
    [actions.setSuccess]: (state, success) => Immutable.merge(state, { success }),
  },
  initialState
)

//
// Selectors
//
const root = (state) => state.newsletter
const error = (state) => root(state).error
const success = (state) => root(state).success
const connected = (state) => root(state).connected
const complete = (state) => root(state).complete

export const selectors = {
  error,
  success,
  connected,
  complete,
}
