module.exports = {
  url: process.env.APP_URL,
  api: process.env.API_URL,
  intlLocales: process.env.INTL_LOCALES,
  intlRedirect: process.env.INTL_REDIRECT,
  intlDefaultLocale: process.env.INTL_DEFAULT_LOCALE,
  basicAuthUser: process.env.BASIC_AUTH_USER,
  basicAuthPassword: process.env.BASIC_AUTH_PASSWORD,
  googleTagManagerKey: process.env.GOOGLE_TAG_MANAGER_KEY,
}
